import styled from 'styled-components';

export const AboutWrapperCss = styled.div`
  padding: 100px 0;

  h1 {
    font-size: 42px;
    margin-bottom: 50px;
  }

  p {
    font-size: 22px;
    line-height: 1.7;
    margin-bottom: 30px;
  }
`;

export const SocialWrapperCss = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  svg {
    width: 50px;
    height: 50px;
    fill: ${props => props.theme.gray700};
    cursor: pointer;
    margin-right: 30px;
    path {
      fill: ${props => props.theme.gray700};
    }
    &:hover {
      fill: ${props => props.theme.gray1000};
      path {
        fill: ${props => props.theme.gray1000};
      }
    }
  }
`;
