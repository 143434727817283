import React from 'react';
import moment from 'moment';
import { AboutWrapperCss, SocialWrapperCss } from './index.css';

import InstagramSvg from '../../images/svg/social/instagram.svg';
import GithubSvg from '../../images/svg/social/github.svg';
import LinkedinSvg from '../../images/svg/social/linkedin.svg';

const About = () => {
  const age = moment().diff(moment('1994-08-03'), 'years');

  return (
    <AboutWrapperCss>
      <h1>
        Hello{' '}
        <span role="img" aria-label="wave">
          👋
        </span>
      </h1>
      <p>
        My name is Razvan Soare, I&apos;m a {age} year old, Front End Engineer
        based in UnitedKingdom{' '}
        <span role="img" aria-label="uk">
          🇬🇧
        </span>
        . I describe myself as a passionate developer who loves coding, open
        challanges and the web platform{' '}
        <span role="img" aria-label="heart">
          ❤️
        </span>
        .
      </p>
      <p>
        Aside from my job, I enjoy reading techonology related articles,
        especially React and javascript related. One of the reasons I love React
        is that no matter how much you know there will always be new things to
        discover. Also I enjoy writing technical things{' '}
        <span role="img" aria-label="write">
          ✍️
        </span>{' '}
        at my blog, hoping I can share some of the most captivating dev related
        stories.
      </p>
      <p>
        In my free time you can find me longboarding{' '}
        <span role="img" aria-label="skateboard">
          🛹
        </span>{' '}
        (especially on my electric one
        <span role="img" aria-label="heart-eyes">
          😍
        </span>
        ), running{' '}
        <span role="img" aria-label="heart">
          🏃‍♂️
        </span>{' '}
        or most likely playing some Assassin&apos;s Creed.
      </p>
      <br />
      <br />
      <p>Follow me on</p>
      <SocialWrapperCss>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.linkedin.com/in/razvan-soare/"
        >
          <LinkedinSvg />
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://github.com/razvan-soare"
        >
          <GithubSvg />
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.instagram.com/razvan_soare_"
        >
          <InstagramSvg />
        </a>
      </SocialWrapperCss>
    </AboutWrapperCss>
  );
};

export default About;
