import React from 'react';

import AboutComponent from 'components/About';
import Layout from 'components/layout';
import SEO from '../utils/seo';

const About = () => {
  return (
    <Layout>
      <SEO title="About" />
      <AboutComponent />
    </Layout>
  );
};

export default About;
